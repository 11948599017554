$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;
.header-container {
    position: relative; // Context for absolute positioning
    text-align: center;
    color: white;
    width: 100%;
    height: 100%;
    overflow: hidden;
    @media (min-width: $breakpoint-sm) {
        height: 100%;
    }
    @media (min-width: $breakpoint-md) {
        height: 500px;
    }
    @media (min-width: $breakpoint-lg) {
        height: 500px;
    }
    @media (min-width: $breakpoint-xl) {
        height: 500px;
    }
    @media (min-width: $breakpoint-xxl) {
        height: 600px;
    }
    .header-image {
        width: 100%;
        height: auto;
        display: block;
    }
    .header-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
        font-size: 1rem;
        @media (min-width: $breakpoint-sm) {
            top: 50%;
        }
        @media (min-width: $breakpoint-md) {
            top: 7%;
        }
        @media (min-width: $breakpoint-lg) {
            top: 7%;
        }
        @media (min-width: $breakpoint-xl) {
            top: 7%;
        }
        @media (min-width: $breakpoint-xxl) {
            top: 7%;
        }
        h1 {
            font-size: 1.2rem;
            margin: 0;
        }
        h2 {
            font-size: 1.5rem;
            margin: 0;
            @media (max-width: 992px) {
                // Medium screens and below
                font-size: 1.2rem;
            }
            @media (max-width: 768px) {
                // Small screens and below
                font-size: 1rem;
            }
        }
    }
}

.navbar {
    min-height: 56px; // Consistent navbar height
    display: flex;
    align-items: stretch;
    .navbar-nav {
        display: flex;
        flex-direction: row; // Default horizontal layout for desktop
        .nav-item {
            .nav-link {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                padding: 0 1rem;
                margin: 0;
                border-radius: 0;
                text-decoration: none;
                color: white;
                &.active {
                    background-color: #495057; // Active link background
                    color: #ffffff !important;
                }
                &:hover {
                    background-color: #343a40;
                    color: #ffffff !important;
                }
            }
        }
    }
    // Vertical navbar on mobile
    .navbar-collapse {
        @media (max-width: 992px) {
            .navbar-nav {
                flex-direction: column; // Change to vertical layout
                width: 100%; // Ensure full width in collapsed state
            }
            .nav-item {
                margin: 0;
                width: 100%; // Each item spans the full width
                .nav-link {
                    justify-content: start; // Align text to the left
                    padding: 0.75rem 1rem; // Adjust padding for better touch targets
                }
            }
        }
    }
    .navbar-toggler {
        align-self: center;
        border-color: rgba(255, 255, 255, 0.1); // Optional: improve visibility of the toggler icon
    }
    .navbar-toggler-icon {
        filter: brightness(200%);
    }
}

.nav-item {
    margin: -33px 0;
}

.pilot-details {
    .nav-item {
        margin: -27px 0;
        background-color: #3a3a3a;
        border-top-right-radius: 15px;
        .btn {
            border-top-right-radius: 15px !important;
            &.active {
                background-color: #1e1f26 !important;
            }
        }
        .pilot-list {
            &.active {
                background-color: #2e2f39 !important;
            }
        }
        &.active {
            margin: -29px 0 !important;
            background-color: #1e1f26 !important;
            border-top: white solid 1px;
            border-right: white solid 1px;
            border-left: white solid 1px;
        }
    }
}

// Define gradient styles
.bg {
    &-USSR {
        background: linear-gradient(90deg, #1e202c90, #282b3b90) !important; // Black to White gradient
    }
    &-RAF {
        background: linear-gradient(90deg, #1e202c90, #282b3b90) !important; // Black to White gradient
    }
    &-USAAF {
        background: linear-gradient(90deg, #1e202c90, #282b3b90) !important; // Black to White gradient
    }
    &-Luft {
        background: linear-gradient(90deg, #1e202c90, #282b3b90) !important; // Black to White gradient
    }
}

.progress-rank-bar {
    background-color: #515151 !important;
}

.rank-text {
    font-size: .8rem;
}

.text {
    &-small {
        font-size: .8rem;
    }
    &-large {
        font-size: 1.2rem;
    }
}

.text {
    &-gray {
        color: #b3b3b3 !important;
    }
    &-color {
        &-green {
            color: rgb(0, 172, 0) !important;
            &-light {
                color: rgb(72, 255, 72) !important;
            }
            &-wounded {
                color: rgb(2, 223, 2) !important;
            }
        }
        &-red {
            color: rgb(255, 0, 0) !important;
        }
    }
}

.border {
    &-color {
        &-gray {
            --bs-border-color: #787878 !important;
        }
    }
}

.table-darker {
    --bs-table-color: rgb(186, 186, 186) !important;
    --bs-table-bg: #121212 !important;
    --bs-table-striped-bg: #181818 !important;
    --bs-table-striped-color: rgb(186, 186, 186) !important;
    --bs-table-hover-color: rgb(186, 186, 186) !important;
    --bs-table-hover-bg: #1e1e1e !important;
    background-color: #121212 !important; // Darker background
    color: #ffffff !important; // White text
    &.table-striped tbody tr:nth-of-type(odd) {
        background-color: #181818 !important; // Slightly lighter for striping
    }
    &.table-hover tbody tr:hover {
        background-color: #1e1e1e !important; // Even lighter on hover
    }
    &.table-bordered {
        border-color: #333333 !important; // Border color
    }
    // Target elements inside the table
    thead {
        background-color: #1a1a1a !important; // Darker header
    }
    th,
    td {
        border-color: #333333 !important; // Cell border color
    }
}

.accordion-btn {
    background-color: transparent !important;
    color: #ffffff !important; // White text
    &::after {
        color: #ffffff !important;
    }
    &.accordion-body {
        padding: .01rem 0rem 0rem 0rem;
    }
}

.accordion-border {
    &.accordion {
        border: 0;
        background-color: transparent !important;
    }
}

.cbradio-container {
    width: 1000px;
    height: auto;
    @media (min-width: $breakpoint-sm) {
        width: $breakpoint-sm;
    }
    @media (min-width: $breakpoint-md) {
        width: $breakpoint-md;
    }
    @media (min-width: $breakpoint-lg) {
        width: 1000px;
    }
    @media (min-width: $breakpoint-xl) {
        width: 1000px;
    }
    @media (min-width: $breakpoint-xxl) {
        width: 1000px;
    }
}

.container-online-players {
    width: 300px;
    height: auto;
    @media (min-width: $breakpoint-sm) {
        width: $breakpoint-sm;
    }
    @media (min-width: $breakpoint-md) {
        width: $breakpoint-md;
    }
    @media (min-width: $breakpoint-lg) {
        width: 300px;
    }
    @media (min-width: $breakpoint-xl) {
        width: 300px;
        ;
    }
    @media (min-width: $breakpoint-xxl) {
        width: 300px;
    }
}

.progress-bar-dark {
    --bs-progress-bg: black !important;
}

.outlined-text {
    color: white;
    /* Text color */
    text-shadow: -1px -1px 0 black, /* Top-left shadow */
    1px -1px 0 black, /* Top-right shadow */
    -1px 1px 0 black, /* Bottom-left shadow */
    1px 1px 0 black;
    /* Bottom-right shadow */
    font-weight: bold;
    /* Optional for stronger effect */
}

.text-white {
    color: white !important;
}

// Calendar styles
.calendar-container {
    margin: 20px auto;
    width: 100%; // Ensure it adapts to parent width
    .calendar {
        width: 100%; // Full width
        text-align: center;
        table-layout: fixed; // Equal column width
        th,
        td {
            padding: 5px;
            font-size: 0.6rem; // Text size for all cells
            word-wrap: break-word;
            height: auto;
            &.empty {
                background-color: #e9ecef;
            }
            &.mission-day {
                background-color: #dc3545;
                color: white;
                font-weight: bold;
            }
        }
        th {
            background-color: #212529;
            color: white;
            width: 5%;
            text-align: center;
        }
        td {
            background-color: #f8f9fa;
            border: 1px solid #dee2e6;
            height: 10px;
        }
        tr {
            height: 55px;
        }
    }
    // Responsive adjustments
    @media (max-width: 768px) {
        .calendar {
            th,
            td {
                font-size: 0.8rem;
                padding: 4px;
            }
        }
    }
    @media (max-width: 576px) {
        .calendar {
            th,
            td {
                font-size: 0.7rem; // Further reduce font size for small screens
                padding: 2px;
            }
        }
    }
}

.calendar-container {
    .calendar {
        th {
            background-color: #343a40; // Dark header background
        }
        td {
            background-color: #212529; // Dark cell background
        }
        .mission-day {
            background-color: #495057 !important; // Highlighted mission day
            color: #ff7f7f; // Custom color for the mission day
            font-weight: bold;
        }
    }
    .text-light {
        color: #ffffff; // Ensure text is bright on dark background
    }
}

.in-campaign-range {
    background-color: rgba(18, 0, 65, 0.162) !important;
    /* tinted highlight */
    position: relative;
    /* Optional: a subtle border or outline */
    /* border: 1px solid lime; */
}


/* The wrapper for the video to fill the container */

.video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* hides any overflow from the iframe */
    z-index: 1;
    /* behind the text overlay */
}

.header-video {
    position: relative;
    top: -700px;
    left: 0;
    height: 1920px;
    width: 100%;
    pointer-events: none;
    object-fit: cover;
}