/* Ensure the scoped row styling */

table {
    tr.striped-row {
        --bs-table-bg: #2c3034;
    }
}

body {
    font-size: .9rem;
}