.paper-doll {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  pointer-events: none;
}
.paper-doll-background {
  background-color: rgb(36, 36, 36);
}

.awards-box {
  background-color: #242424;
}
.awards-ribbon {
  width: 27px;
  height: 7px;
  pointer-events: none;
}
@media (min-width: 576px) {
  .awards-ribbon {
    width: 33px;
    height: 10px;
  }
}
@media (min-width: 768px) {
  .awards-ribbon {
    width: 45px;
    height: 13px;
  }
}
@media (min-width: 992px) {
  .awards-ribbon {
    width: 61px;
    height: 19px;
  }
}
@media (min-width: 1200px) {
  .awards-ribbon {
    width: 75px;
    height: 23px;
  }
}
@media (min-width: 1400px) {
  .awards-ribbon {
    width: 87px;
    height: 26px;
  }
}
.awards-ribbon-div-1 {
  position: absolute;
  top: 43.9%;
  left: 13.5%;
  z-index: 100;
}
@media (min-width: 576px) {
  .awards-ribbon-div-1 {
    top: 43.9%;
    left: 13.5%;
  }
}
@media (min-width: 768px) {
  .awards-ribbon-div-1 {
    top: 44.9%;
    left: 13.5%;
  }
}
@media (min-width: 992px) {
  .awards-ribbon-div-1 {
    top: 45.9%;
    left: 13.5%;
  }
}
@media (min-width: 1200px) {
  .awards-ribbon-div-1 {
    top: 45.9%;
    left: 13.5%;
  }
}
@media (min-width: 1400px) {
  .awards-ribbon-div-1 {
    top: 45.9%;
    left: 13.5%;
  }
}
.awards-ribbon-div-2 {
  position: absolute;
  top: 42.1%;
  left: 13.5%;
  z-index: 100;
}
@media (min-width: 576px) {
  .awards-ribbon-div-2 {
    top: 41.5%;
    left: 13.5%;
  }
}
@media (min-width: 768px) {
  .awards-ribbon-div-2 {
    top: 42.6%;
    left: 13.5%;
  }
}
@media (min-width: 992px) {
  .awards-ribbon-div-2 {
    top: 43.5%;
    left: 13.5%;
  }
}
@media (min-width: 1200px) {
  .awards-ribbon-div-2 {
    top: 43.5%;
    left: 13.5%;
  }
}
@media (min-width: 1400px) {
  .awards-ribbon-div-2 {
    top: 43.5%;
    left: 13.5%;
  }
}
.awards-dispatches-medals-box {
  position: absolute;
  color: black;
  transform: rotate(2deg);
  top: 41.6%;
  left: 38%;
  font-size: 0.2rem;
  pointer-events: none;
}
@media (min-width: 576px) {
  .awards-dispatches-medals-box {
    top: 41.6%;
    left: 38%;
    font-size: 0.3rem;
  }
}
@media (min-width: 768px) {
  .awards-dispatches-medals-box {
    top: 41.4%;
    left: 37%;
    font-size: 0.45rem;
  }
}
@media (min-width: 992px) {
  .awards-dispatches-medals-box {
    top: 41.2%;
    left: 38%;
    font-size: 0.6rem;
  }
}
@media (min-width: 1200px) {
  .awards-dispatches-medals-box {
    top: 41.2%;
    left: 37%;
    font-size: 0.7rem;
  }
}
@media (min-width: 1400px) {
  .awards-dispatches-medals-box {
    top: 41.4%;
    left: 38%;
    font-size: 0.8rem;
  }
}
.awards-dispatches-modal {
  position: absolute;
  color: black;
  transform: rotate(2deg);
  top: 41.5%;
  left: 39.6%;
  font-size: 1rem;
  pointer-events: none;
}
@media (min-width: 576px) {
  .awards-dispatches-modal {
    top: 41.5%;
    left: 39.6%;
    font-size: 1rem;
  }
}
@media (min-width: 768px) {
  .awards-dispatches-modal {
    top: 41.5%;
    left: 40.6%;
    font-size: 1.1rem;
  }
}
@media (min-width: 992px) {
  .awards-dispatches-modal {
    top: 41.7%;
    left: 40.6%;
    font-size: 1.5rem;
  }
}
@media (min-width: 1200px) {
  .awards-dispatches-modal {
    top: 41.7%;
    left: 40.6%;
    font-size: 1.5rem;
  }
}
@media (min-width: 1400px) {
  .awards-dispatches-modal {
    top: 41.7%;
    left: 40.6%;
    font-size: 1.5rem;
  }
}/*# sourceMappingURL=awards.css.map */