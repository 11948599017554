html,
body {
  height: 100%;
  margin: 0;
  color: white !important;
}

body {
  background: linear-gradient(165deg, #121212, #3f3f3f);
  background-attachment: fixed;
  background-size: cover;
  font-family: Arial, sans-serif;
  color: white;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.h4-header {
  font-size: 1.2rem;
}

.pilot-stats .table th:nth-child(1),
.pilot-stats .table td:nth-child(1) {
  width: 30%;
}
.pilot-stats .table th:nth-child(2), .pilot-stats .table th:nth-child(3), .pilot-stats .table th:nth-child(4), .pilot-stats .table th:nth-child(5), .pilot-stats .table th:nth-child(6), .pilot-stats .table th:nth-child(7), .pilot-stats .table th:nth-child(8),
.pilot-stats .table td:nth-child(2),
.pilot-stats .table td:nth-child(3),
.pilot-stats .table td:nth-child(4),
.pilot-stats .table td:nth-child(5),
.pilot-stats .table td:nth-child(6),
.pilot-stats .table td:nth-child(7),
.pilot-stats .table td:nth-child(8) {
  width: 10%;
}
@media (max-width: 768px) {
  .pilot-stats .table th:nth-child(1),
  .pilot-stats .table td:nth-child(1) {
    width: 50%;
  }
  .pilot-stats .table th:nth-child(2), .pilot-stats .table th:nth-child(3), .pilot-stats .table th:nth-child(4), .pilot-stats .table th:nth-child(5), .pilot-stats .table th:nth-child(6), .pilot-stats .table th:nth-child(7), .pilot-stats .table th:nth-child(8),
  .pilot-stats .table td:nth-child(2),
  .pilot-stats .table td:nth-child(3),
  .pilot-stats .table td:nth-child(4),
  .pilot-stats .table td:nth-child(5),
  .pilot-stats .table td:nth-child(6),
  .pilot-stats .table td:nth-child(7),
  .pilot-stats .table td:nth-child(8) {
    width: 12.5%;
  }
  .pilot-stats .table th:nth-child(7), .pilot-stats .table th:nth-child(8),
  .pilot-stats .table td:nth-child(7),
  .pilot-stats .table td:nth-child(8) {
    display: none;
  }
}

.country-icon {
  text-align: center;
  vertical-align: middle;
}

.country-icon img {
  display: inline-block;
  margin: 0 auto;
}

.map-date {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 5px 10px;
  border-radius: 5px;
  margin-top: 20px;
}

.small-btn {
  padding: 0.25rem 0.5rem !important;
  font-size: 0.7rem !important;
}

.text-subtitle {
  font-size: 1rem !important;
}/*# sourceMappingURL=index.css.map */