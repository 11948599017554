.main {
    font-size: .8rem;
}

h2,
h4 {
    color: white !important;
}

.accordion-background {
    background-color: #212529 !important;
    color: white;
    border: none;
    &::after {
        color: white;
    }
    &:focus {
        color: white;
    }
}

.full-width-bar {
    width: 100%;
    height: 2px;
    /* Adjust height as needed */
    background-color: #b5b5b5;
    /* Background color */
    color: white;
    text-align: center;
    line-height: 50px;
    font-size: 1.2rem;
}

.airfield-closed-text {
    color: white;
}

.airfield-details {
    &-icon-column {
        width: 10%;
    }
    &-plane-name-column {
        width: 30%;
    }
}

.weather-forecast-container,
.campaign-status-container,
.campaign-story-events-container,
.war-effort-container,
.calendar-container {
    font-size: .9rem;
}

.campaign-status-title-column {
    width: 25%;
}