$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;
.paper {
    &-doll {
        width: 100%;
        height: 100%;
        object-fit: cover;
        pointer-events: none;
        &-background {
            background-color: rgb(36, 36, 36);
        }
    }
}

.awards {
    &-box {
        background-color: #242424;
    }
    &-ribbon {
        width: 27px;
        height: 7px;
        pointer-events: none;
        @media (min-width: $breakpoint-sm) {
            width: 33px;
            height: 10px;
        }
        @media (min-width: $breakpoint-md) {
            width: 45px;
            height: 13px;
        }
        @media (min-width: $breakpoint-lg) {
            width: 61px;
            height: 19px;
        }
        @media (min-width: $breakpoint-xl) {
            width: 75px;
            height: 23px;
        }
        @media (min-width: $breakpoint-xxl) {
            width: 87px;
            height: 26px;
        }
        &-div {
            &-1 {
                position: absolute;
                top: 43.9%;
                left: 13.5%;
                z-index: 100;
                @media (min-width: $breakpoint-sm) {
                    top: 43.9%;
                    left: 13.5%;
                }
                @media (min-width: $breakpoint-md) {
                    top: 44.9%;
                    left: 13.5%;
                }
                @media (min-width: $breakpoint-lg) {
                    top: 45.9%;
                    left: 13.5%;
                }
                @media (min-width: $breakpoint-xl) {
                    top: 45.9%;
                    left: 13.5%;
                }
                @media (min-width: $breakpoint-xxl) {
                    top: 45.9%;
                    left: 13.5%;
                }
            }
            &-2 {
                position: absolute;
                top: 42.1%;
                left: 13.5%;
                z-index: 100;
                @media (min-width: $breakpoint-sm) {
                    top: 41.5%;
                    left: 13.5%;
                }
                @media (min-width: $breakpoint-md) {
                    top: 42.6%;
                    left: 13.5%;
                }
                @media (min-width: $breakpoint-lg) {
                    top: 43.5%;
                    left: 13.5%;
                }
                @media (min-width: $breakpoint-xl) {
                    top: 43.5%;
                    left: 13.5%;
                }
                @media (min-width: $breakpoint-xxl) {
                    top: 43.5%;
                    left: 13.5%;
                }
            }
        }
    }
    &-dispatches {
        &-medals-box {
            position: absolute;
            color: black;
            transform: rotate(2deg);
            top: 41.6%;
            left: 38%;
            font-size: 0.2rem;
            pointer-events: none;
            @media (min-width: $breakpoint-sm) {
                top: 41.6%;
                left: 38%;
                font-size: 0.3rem;
            }
            @media (min-width: $breakpoint-md) {
                top: 41.4%;
                left: 37%;
                font-size: 0.45rem;
            }
            @media (min-width: $breakpoint-lg) {
                top: 41.2%;
                left: 38%;
                font-size: 0.6rem;
            }
            @media (min-width: $breakpoint-xl) {
                top: 41.2%;
                left: 37%;
                font-size: 0.7rem;
            }
            @media (min-width: $breakpoint-xxl) {
                top: 41.4%;
                left: 38%;
                font-size: .8rem;
            }
        }
        &-modal {
            position: absolute;
            color: black;
            transform: rotate(2deg);
            top: 41.5%;
            left: 39.6%;
            font-size: 1rem;
            pointer-events: none;
            @media (min-width: $breakpoint-sm) {
                top: 41.5%;
                left: 39.6%;
                font-size: 1rem;
            }
            @media (min-width: $breakpoint-md) {
                top: 41.5%;
                left: 40.6%;
                font-size: 1.1rem;
            }
            @media (min-width: $breakpoint-lg) {
                top: 41.7%;
                left: 40.6%;
                font-size: 1.5rem;
            }
            @media (min-width: $breakpoint-xl) {
                top: 41.7%;
                left: 40.6%;
                font-size: 1.5rem;
            }
            @media (min-width: $breakpoint-xxl) {
                top: 41.7%;
                left: 40.6%;
                font-size: 1.5rem;
            }
        }
    }
}