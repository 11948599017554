.header-container {
  position: relative;
  text-align: center;
  color: white;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
@media (min-width: 576px) {
  .header-container {
    height: 100%;
  }
}
@media (min-width: 768px) {
  .header-container {
    height: 500px;
  }
}
@media (min-width: 992px) {
  .header-container {
    height: 500px;
  }
}
@media (min-width: 1200px) {
  .header-container {
    height: 500px;
  }
}
@media (min-width: 1400px) {
  .header-container {
    height: 600px;
  }
}
.header-container .header-image {
  width: 100%;
  height: auto;
  display: block;
}
.header-container .header-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  font-size: 1rem;
}
@media (min-width: 576px) {
  .header-container .header-text {
    top: 50%;
  }
}
@media (min-width: 768px) {
  .header-container .header-text {
    top: 7%;
  }
}
@media (min-width: 992px) {
  .header-container .header-text {
    top: 7%;
  }
}
@media (min-width: 1200px) {
  .header-container .header-text {
    top: 7%;
  }
}
@media (min-width: 1400px) {
  .header-container .header-text {
    top: 7%;
  }
}
.header-container .header-text h1 {
  font-size: 1.2rem;
  margin: 0;
}
.header-container .header-text h2 {
  font-size: 1.5rem;
  margin: 0;
}
@media (max-width: 992px) {
  .header-container .header-text h2 {
    font-size: 1.2rem;
  }
}
@media (max-width: 768px) {
  .header-container .header-text h2 {
    font-size: 1rem;
  }
}

.navbar {
  min-height: 56px;
  display: flex;
  align-items: stretch;
}
.navbar .navbar-nav {
  display: flex;
  flex-direction: row;
}
.navbar .navbar-nav .nav-item .nav-link {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
  margin: 0;
  border-radius: 0;
  text-decoration: none;
  color: white;
}
.navbar .navbar-nav .nav-item .nav-link.active {
  background-color: #495057;
  color: #ffffff !important;
}
.navbar .navbar-nav .nav-item .nav-link:hover {
  background-color: #343a40;
  color: #ffffff !important;
}
@media (max-width: 992px) {
  .navbar .navbar-collapse .navbar-nav {
    flex-direction: column;
    width: 100%;
  }
  .navbar .navbar-collapse .nav-item {
    margin: 0;
    width: 100%;
  }
  .navbar .navbar-collapse .nav-item .nav-link {
    justify-content: start;
    padding: 0.75rem 1rem;
  }
}
.navbar .navbar-toggler {
  align-self: center;
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar .navbar-toggler-icon {
  filter: brightness(200%);
}

.nav-item {
  margin: -33px 0;
}

.pilot-details .nav-item {
  margin: -27px 0;
  background-color: #3a3a3a;
  border-top-right-radius: 15px;
}
.pilot-details .nav-item .btn {
  border-top-right-radius: 15px !important;
}
.pilot-details .nav-item .btn.active {
  background-color: #1e1f26 !important;
}
.pilot-details .nav-item .pilot-list.active {
  background-color: #2e2f39 !important;
}
.pilot-details .nav-item.active {
  margin: -29px 0 !important;
  background-color: #1e1f26 !important;
  border-top: white solid 1px;
  border-right: white solid 1px;
  border-left: white solid 1px;
}

.bg-USSR {
  background: linear-gradient(90deg, rgba(30, 32, 44, 0.5647058824), rgba(40, 43, 59, 0.5647058824)) !important;
}
.bg-RAF {
  background: linear-gradient(90deg, rgba(30, 32, 44, 0.5647058824), rgba(40, 43, 59, 0.5647058824)) !important;
}
.bg-USAAF {
  background: linear-gradient(90deg, rgba(30, 32, 44, 0.5647058824), rgba(40, 43, 59, 0.5647058824)) !important;
}
.bg-Luft {
  background: linear-gradient(90deg, rgba(30, 32, 44, 0.5647058824), rgba(40, 43, 59, 0.5647058824)) !important;
}

.progress-rank-bar {
  background-color: #515151 !important;
}

.rank-text {
  font-size: 0.8rem;
}

.text-small {
  font-size: 0.8rem;
}
.text-large {
  font-size: 1.2rem;
}

.text-gray {
  color: #b3b3b3 !important;
}
.text-color-green {
  color: rgb(0, 172, 0) !important;
}
.text-color-green-light {
  color: rgb(72, 255, 72) !important;
}
.text-color-green-wounded {
  color: rgb(2, 223, 2) !important;
}
.text-color-red {
  color: rgb(255, 0, 0) !important;
}

.border-color-gray {
  --bs-border-color: #787878 !important;
}

.table-darker {
  --bs-table-color: rgb(186, 186, 186) !important;
  --bs-table-bg: #121212 !important;
  --bs-table-striped-bg: #181818 !important;
  --bs-table-striped-color: rgb(186, 186, 186) !important;
  --bs-table-hover-color: rgb(186, 186, 186) !important;
  --bs-table-hover-bg: #1e1e1e !important;
  background-color: #121212 !important;
  color: #ffffff !important;
}
.table-darker.table-striped tbody tr:nth-of-type(odd) {
  background-color: #181818 !important;
}
.table-darker.table-hover tbody tr:hover {
  background-color: #1e1e1e !important;
}
.table-darker.table-bordered {
  border-color: #333333 !important;
}
.table-darker thead {
  background-color: #1a1a1a !important;
}
.table-darker th,
.table-darker td {
  border-color: #333333 !important;
}

.accordion-btn {
  background-color: transparent !important;
  color: #ffffff !important;
}
.accordion-btn::after {
  color: #ffffff !important;
}
.accordion-btn.accordion-body {
  padding: 0.01rem 0rem 0rem 0rem;
}

.accordion-border.accordion {
  border: 0;
  background-color: transparent !important;
}

.cbradio-container {
  width: 1000px;
  height: auto;
}
@media (min-width: 576px) {
  .cbradio-container {
    width: 576px;
  }
}
@media (min-width: 768px) {
  .cbradio-container {
    width: 768px;
  }
}
@media (min-width: 992px) {
  .cbradio-container {
    width: 1000px;
  }
}
@media (min-width: 1200px) {
  .cbradio-container {
    width: 1000px;
  }
}
@media (min-width: 1400px) {
  .cbradio-container {
    width: 1000px;
  }
}

.container-online-players {
  width: 300px;
  height: auto;
}
@media (min-width: 576px) {
  .container-online-players {
    width: 576px;
  }
}
@media (min-width: 768px) {
  .container-online-players {
    width: 768px;
  }
}
@media (min-width: 992px) {
  .container-online-players {
    width: 300px;
  }
}
@media (min-width: 1200px) {
  .container-online-players {
    width: 300px;
  }
}
@media (min-width: 1400px) {
  .container-online-players {
    width: 300px;
  }
}

.progress-bar-dark {
  --bs-progress-bg: black !important;
}

.outlined-text {
  color: white;
  /* Text color */
  text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
  /* Bottom-right shadow */
  font-weight: bold;
  /* Optional for stronger effect */
}

.text-white {
  color: white !important;
}

.calendar-container {
  margin: 20px auto;
  width: 100%;
}
.calendar-container .calendar {
  width: 100%;
  text-align: center;
  table-layout: fixed;
}
.calendar-container .calendar th,
.calendar-container .calendar td {
  padding: 5px;
  font-size: 0.6rem;
  word-wrap: break-word;
  height: auto;
}
.calendar-container .calendar th.empty,
.calendar-container .calendar td.empty {
  background-color: #e9ecef;
}
.calendar-container .calendar th.mission-day,
.calendar-container .calendar td.mission-day {
  background-color: #dc3545;
  color: white;
  font-weight: bold;
}
.calendar-container .calendar th {
  background-color: #212529;
  color: white;
  width: 5%;
  text-align: center;
}
.calendar-container .calendar td {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  height: 10px;
}
.calendar-container .calendar tr {
  height: 55px;
}
@media (max-width: 768px) {
  .calendar-container .calendar th,
  .calendar-container .calendar td {
    font-size: 0.8rem;
    padding: 4px;
  }
}
@media (max-width: 576px) {
  .calendar-container .calendar th,
  .calendar-container .calendar td {
    font-size: 0.7rem;
    padding: 2px;
  }
}

.calendar-container .calendar th {
  background-color: #343a40;
}
.calendar-container .calendar td {
  background-color: #212529;
}
.calendar-container .calendar .mission-day {
  background-color: #495057 !important;
  color: #ff7f7f;
  font-weight: bold;
}
.calendar-container .text-light {
  color: #ffffff;
}

.in-campaign-range {
  background-color: rgba(18, 0, 65, 0.162) !important;
  /* tinted highlight */
  position: relative;
  /* Optional: a subtle border or outline */
  /* border: 1px solid lime; */
}

/* The wrapper for the video to fill the container */
.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* hides any overflow from the iframe */
  z-index: 1;
  /* behind the text overlay */
}

.header-video {
  position: relative;
  top: -700px;
  left: 0;
  height: 1920px;
  width: 100%;
  pointer-events: none;
  -o-object-fit: cover;
     object-fit: cover;
}/*# sourceMappingURL=header.css.map */