.spoiler {
    cursor: pointer;
    color: transparent;
    display: flex;
    /* Hide text */
    background-color: #8c8c8c;
    /* Add background for hidden text */
    border: 1px dashed #666;
    /* Optional: border to indicate a spoiler */
    width: 100%;
    height: 200px;
}

.spoiler.revealed {
    color: inherit;
    display: inline;
    /* Show text when revealed */
    background-color: transparent;
    /* Remove background */
    border: none;
    /* Optional: remove border when revealed */
}