.main {
  color: white;
}

.custom-dark-modal .btn-close {
  filter: invert(1);
}

.custom-dark-modal .modal-content {
  background-color: #212529;
  color: #f8f9fa;
}

.custom-dark-modal .modal-header,
.custom-dark-modal .modal-footer {
  border-color: #444;
}

.modal-xxl {
  --bs-modal-width: 95%;
}

.modal-header .btn-close {
  filter: invert(1);
}
.modal-header .btn-close:hover {
  filter: invert(0.8);
}
.modal-header .btn-close:focus {
  box-shadow: none;
}/*# sourceMappingURL=modal.css.map */