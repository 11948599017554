.supply-bar-container {
    position: relative;
    width: 100%;
    background-color: #515151;
    height: 20px;
    border-radius: 4px;
    overflow: hidden;
}

.supply-bar {
    height: 100%;
    transition: width 0.3s ease-in-out;
}

.supply-bar-airfield-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-size: 0.7rem;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
    pointer-events: none;
}