.main {
  font-size: 0.8rem;
}

h2,
h4 {
  color: white !important;
}

.accordion-background {
  background-color: #212529 !important;
  color: white;
  border: none;
}
.accordion-background::after {
  color: white;
}
.accordion-background:focus {
  color: white;
}

.full-width-bar {
  width: 100%;
  height: 2px;
  /* Adjust height as needed */
  background-color: #b5b5b5;
  /* Background color */
  color: white;
  text-align: center;
  line-height: 50px;
  font-size: 1.2rem;
}

.airfield-closed-text {
  color: white;
}

.airfield-details-icon-column {
  width: 10%;
}
.airfield-details-plane-name-column {
  width: 30%;
}

.weather-forecast-container,
.campaign-status-container,
.campaign-story-events-container,
.war-effort-container,
.calendar-container {
  font-size: 0.9rem;
}

.campaign-status-title-column {
  width: 25%;
}/*# sourceMappingURL=main.css.map */