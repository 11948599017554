html,
body {
    height: 100%; // Ensure both html and body take up full viewport height
    margin: 0; // Remove default margins
    color: white !important;
}

body {
    background: linear-gradient(165deg, #121212, #3f3f3f);
    background-attachment: fixed; // Ensure background stays in place during scrolling
    background-size: cover; // Ensure it covers the entire area
    font-family: Arial, sans-serif; // Optional: Set a default font
    color: white;
}

#root {
    min-height: 100%; // Ensure the React root container also takes full height
    display: flex; // Optional: Use flexbox to structure content if needed
    flex-direction: column;
}

.h4-header {
    font-size: 1.2rem;
}

.pilot-stats {
    .table {
        th,
        td {
            &:nth-child(1) {
                // Pilot Name
                width: 30%; // Largest column for desktop
            }
            &:nth-child(2), // Coalition
            &:nth-child(3), // Air Kills
            &:nth-child(4), // Ground Kills
            &:nth-child(5), // Missions
            &:nth-child(6), // Flight Time
            &:nth-child(7), // Air Kills Per Hour
            &:nth-child(8) {
                // Ground Kills Per Hour
                width: 10%;
            }
        }
        // Adjust columns for mobile
        @media (max-width: 768px) {
            th,
            td {
                &:nth-child(1) {
                    // Pilot Name
                    width: 50%; // Keep more space for important columns
                }
                &:nth-child(2), // Coalition
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7),
                &:nth-child(8) {
                    width: 12.5%;
                }
                // Optional: Hide less important columns for small screens
                &:nth-child(7),
                &:nth-child(8) {
                    display: none; // Hide Air/Ground Kills per Hour
                }
            }
        }
    }
}

.country-icon {
    text-align: center; // Horizontal centering
    vertical-align: middle; // Ensures proper vertical alignment in tables
}

.country-icon img {
    display: inline-block; // Centers the image horizontally within the cell
    margin: 0 auto; // Adds spacing control if necessary
}

.map-date {
    background-color: rgba(0, 0, 0, 0.7);
    padding: 5px 10px;
    border-radius: 5px;
    margin-top: 20px;
}

.small-btn {
    padding: 0.25rem 0.5rem !important;
    font-size: 0.7rem !important;
}

.text-subtitle {
    font-size: 1rem !important;
}