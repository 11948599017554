.main {
    color: white;
}

.custom-dark-modal .btn-close {
    filter: invert(1); // Makes the close button white
}

.custom-dark-modal .modal-content {
    background-color: #212529; // Ensures a consistent dark background
    color: #f8f9fa; // Light text for readability
}

.custom-dark-modal .modal-header,
.custom-dark-modal .modal-footer {
    border-color: #444; // Adds subtle borders in dark theme
}

.modal-xxl {
    --bs-modal-width: 95%;
}

.modal-header {
    .btn-close {
        filter: invert(1); // Makes the close button white by inverting its color
    }
    .btn-close:hover {
        filter: invert(0.8); // Slightly darker on hover
    }
    .btn-close:focus {
        box-shadow: none; // Remove default focus outline
    }
}